import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import './personalizedcard.css';
import { Layout, Row, Col, Steps, Button, Icon, Card, Affix, Divider, Tag, Menu } from 'antd';
import 'antd/dist/antd.css';
import ReactMapGL from 'react-map-gl';
import {Marker, Popup, NavigationControl} from 'react-map-gl';
import CityPin from './city-pin';
import CityInfo from './city-info';
import Author_Camus from './Author_Camus';
import Author_Doyle from './Author_Doyle';
import LondonFoodMarkets from './LondonFoodMarkets';
import postscribe from 'postscribe';
import ReactGA from 'react-ga';

import CITIES from './cities.json';
import CAMUS from './camus.json';

const { Header, Footer, Sider, Content } = Layout;
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZGRpdmlhY2NoaSIsImEiOiJjanJjODN4ZzUwaTFkNDNtbDNzdHZlbWJzIn0.pR8KP_hxx2LTl3wyoskB3w';
const mapstyle ='mapbox://styles/mapbox/dark-v10';

const { Meta } = Card;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const navStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '10px'
};
var array= [];
var currentcity;

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewport: {
        width: "100%",  //old 70%
        height: 600,
        latitude: 48.863265,
        longitude: 2.3427148,
        zoom: 12,
        bearing:0,
        pitch:0,
        current: 'mail',
      },
      popupInfo: null,
      home: 0,
    }
  }

  _updateViewport = (viewport) => {
     this.setState({viewport});
   }

  handleClick = (e) => {
     console.log('click ', e);
     this.setState({
       current: e.key,
     });
  }

  componentDidMount (){
    postscribe('#tippin-button', '<script src="https://tippin.me/buttons/tip.js" type="text/javascript"></script>')
  }

  initializeReactGA() {
    ReactGA.initialize('UA-138208767-1');
    ReactGA.pageview('/homepage');
  }

  render() {

    if (this.state.home == 0 ){
      return (
        <div style={{backgroundColor:"#FAFAFA"}}>
          <div style={{backgroundSize:'cover', minHeight:"80vh", backgroundPosition:'40% 40%', width:"100%", backgroundImage: "url('https://s3.eu-west-2.amazonaws.com/tinytravel/Thailande+2013+336.JPG')"}}>
            <Row style={{padding:18, color:'white', fontWeight:'700', fontSize:'36px'}} type="flex" justify="space-around" align="bottom">
              <Col span={8}>
                  <i style={{paddingLeft:'10px'}}>TinyTravel</i>
              </Col>
              <Col span={12}>
                <Menu
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}
                mode="horizontal"
                style={{background:'none', color:'white', border:'none'}}
                >
                  <SubMenu title={<span className="submenu-title-wrapper"><Icon type="compass" />TinyTravel Tours</span>}>
                    <MenuItemGroup title="Authors & Artists" style={{backgroundColor:'#7DABD4', color:'white'}}>
                      <Menu.Item style={{margin:'0%'}} key="compass:1" onClick={() => this.setState({home : 1})}>Albert Camus</Menu.Item>
                      <Menu.Item style={{margin:'0%'}} key="compass:2" onClick={() => this.setState({home : 2})}>Sir James Conan Doyle</Menu.Item>
                    </MenuItemGroup>
                    <MenuItemGroup title="Localize" style={{backgroundColor:'#7DABD4', color:'white'}}>
                      <Menu.Item style={{margin:'0%'}} key="compass:3" onClick={() => this.setState({home : 3})}>London Food Markets</Menu.Item>
                    </MenuItemGroup>
                    <MenuItemGroup title="Vinyards, Barrels and Vin..." style={{backgroundColor:'#7DABD4', color:'white'}}>
                      <Menu.Item style={{margin:'0%'}} key="compass:3"> </Menu.Item>
                    </MenuItemGroup>
                  </SubMenu>
                  <Menu.Item disabled key="dollar" style={{color:'white'}}>
                    <Icon type="dollar" style={{color:'white'}}/><b style={{color:'white', fontWeight:'700'}}>Kindness Tip (to the right)</b>
                  </Menu.Item>
                </Menu>
              </Col>
              <Col span={4}>
                <div style={{backgroundColor:'transparent', fontSize:'14px', fontWeight:'700', boxShadow:'none', height:'37px', border:'1px solid white'}} id="tippin-button" data-dest="TinyTravel1"></div>
              </Col>
              {/* <Col span={4}>
                <Button ghost onClick={() => this.setState({home : 0})}> Menu 1 </Button>
              </Col> */}
            </Row>
            <Divider />
            <Row >
              <div style={{padding:100, color:'white', textAlign:'center', verticalAlign:'middle'}}>
                <h2 style={{color:'white', fontSize:'44px'}}>Unique, curated and free do it yourself tours. Fully immerse in a one day topic on your next trip!</h2>
              </div>
            </Row>
            {/*NOTE  minHeight:"80vh" sets to 80% height of screen, backgroundSize:'contain' to scale the iamge to cover the whole div  or backgroundSize:'contain' to fit image inside the div    */}
          </div>
          {/*<Row><h1>Authors & Artists</h1></Row>*/}
          <Row type="flex" justify="space-around" style={{paddingTop:'60px', paddingBottom:'60px', backgroundColor:'#F0F4F8'}}>
            <Col span={6} style={{width:'auto'}}>
            <Tag color="#8888FC">Authors & Artists</Tag>
              <div style={{padding:'5px'}}/>
              <Card style={{width:300}}
                cover={<img alt="example" src="https://upload.wikimedia.org/wikipedia/commons/c/cb/Albert-camus.jpg" />}
                onClick={() => this.setState({home : 1})}
              >
              {/*actions={[<Button block style={{backgroundColor:'#8888FC', color:'white', border:'none', fontWeight:500}}>View Free Tour</Button>]} */}
                <Meta
                title="Albert Camus' Paris"
                description="Hang out at haunts where artists and authors once did. First up, find Albert Camus hang outs on a map of Paris."
                />
              </Card>
              <ul class="ant-card-actions" style={{borderTop:'0px', backgroundColor:'#8888FC'}}>
                <li style={{width:'100%', float: 'left', textAlign: 'center', margin: '12px 0' }}><Button block onClick={() => this.setState({home : 1})} style={{backgroundColor:'#8888FC', color:'white', border:'none', fontWeight:500}}>View Free Tour</Button></li>
              </ul>
            {/*}  <div class="personalized-card personalized-card-bordered">
                <Row>
                <Col span={6}>
                  <div class="ant-card-cover">
                    <img alt="example" src="http://farm8.staticflickr.com/7029/6528050515_025baf14cb.jpg"/>
                  </div>
                </Col>
                <Col span={6}>
                  <div class="ant-card-body">
                  asdfadsfasfdd
                  </div>
                </Col>
                </Row>
              </div> */}
            </Col>
            <Col span={6} style={{width:'auto'}}>
              <Tag color="#8888FC">Authors & Artists</Tag>
              <div style={{padding:'5px'}}/>
              <Card
                style={{width:300}}
                cover={<img alt="example" src="http://clipart-library.com/image_gallery/181375.jpg" />}
                onClick={() => this.setState({home : 2})}
              >
              {/*actions={[<Button block style={{backgroundColor:'#8888FC', color:'white', border:'none', fontWeight:500}}>View Free Tour</Button>]}*/}
                <Meta
                title="Sherlock Holmes"
                description="Follow ficitional characters around their story settings. See how Sir Aurthor Conan Doyle drew up Sherlock Holmes' life in London."
                />
              </Card>
              <ul class="ant-card-actions" style={{borderTop:'0px', backgroundColor:'#8888FC'}}>
                <li style={{width:'100%', float: 'left', textAlign: 'center', margin: '12px 0' }}><Button block onClick={() => this.setState({home : 2})} style={{backgroundColor:'#8888FC', color:'white', border:'none', fontWeight:500}}>View Free Tour</Button></li>
              </ul>
            </Col>
            <Col span={6} style={{width:'auto'}}>
              <Tag color="#65D6AD">Localize</Tag>
              <div style={{padding:'5px'}}/>
              {/*Below is a Card with a unique CTA button so that I can change the color on it */}
              <Card
                style={{width:300}}
                cover={<img alt="example" src="https://www.thepiccadillywestend.co.uk/blog/wp-content/uploads/2018/07/street-food-london.jpg" />}
                onClick={() => this.setState({home : 3})}
              >
                <Meta
                title="Local Foods"
                description="Find the best local places to eat while on your travels. Don't focus on Borough Market, but these much tastier places."
                />
              </Card>
              <ul class="ant-card-actions" style={{borderTop:'0px', backgroundColor:'#65D6AD'}}>
                <li style={{width:'100%', float: 'left', textAlign: 'center', margin: '12px 0' }}><Button block onClick={() => this.setState({home : 3})} style={{backgroundColor:'#65D6AD', color:'white', border:'none', fontWeight:500}}>View Free Tour</Button></li>
              </ul>
            </Col>
          </Row>
            {/* <Divider /> */}
            {/*<Row><h1>Localize.</h1></Row>*/}
          <Row type="flex" justify="space-around">
          <div style={{backgroundSize:'cover', width:"100%",  backgroundImage: "url('https://s3.eu-west-2.amazonaws.com/tinytravel/localhost_3000_new.png')"}}>
            {/*<img style={{width:'100%', display: 'block', height:'400px'}} src="https://s3.eu-west-2.amazonaws.com/tinytravel/localhost_3000_+try2.png"/> */}
              <div style={{padding:100, textAlign:'center', backgroundColor: 'rgba(162, 165, 252, .55)', verticalAlign:'middle'  }}>{/*backgroundColor is #A2A5FC */}
                <h3 style={{color:'white', fontSize:'40px', paddingBottom:'20px'}}>Tiny Travel provides you with free, unique tours that you will not find anywhere else. These are often specific to a topic so that you will fully immerse for a day. Plan ahead for your next trip!</h3>
                6<Button style={{backgroundColor:'#3EBD93', color:'white', border:'none', fontWeight: 600}}>Sign Up For Free</Button>
              </div>
            </div>

          </Row>
          {/* <Divider /> */}
          <Row type="flex" justify="space-around" style={{backgroundColor:'#F0F4F8', paddingTop:'40px', paddingBottom:'40px'}}>
            <Col span={6} style={{width:'auto', paddingTop:'10px'}}>
              <div style={{width:400, paddingTop:'10px', paddingBottom:'10px'}}>
                <h3 style={{paddingLeft:'38px'}}>Companion Websites</h3>
                  <ul>
                    <i><a style={{width:"100%"}} href="www.soupduck.com">Soupduck</a></i> - Learn to eat authentic foods while traveling.
                  </ul>
                  <ul>
                    <i><a style={{width:"100%"}} href="www.withlocals.com">Withlocals</a></i> - Our tours are free and self completed, but at Withlocals you can hire a local to take you around.
                  </ul>
                  <ul>
                    <i><a style={{width:"100%"}} href="www.google.com">Google</a></i> - Be spontanteous, do your own research.
                  </ul>
              </div>
            </Col>
            <Col span={6} style={{width:'auto', paddingTop:'10px', paddingBottom:'10px'}}>
            <div style={{width:400, paddingTop:'10px'}}>
              <h3 style={{paddingLeft:'38px'}}>Our Social Sites</h3>
                <ul>
                  <Button style={{backgroundColor:'#1DA1F2', color:'white', width:"90%"}}><a href="https://twitter.com/TinyTravel1">Twitter</a></Button>
                </ul>
                <ul>
                  <Button disabled style={{backgroundColor:'#517FA4', color:'white', width:"90%"}}><a href="https://www.google.com">Instagram</a></Button>
                </ul>
                <ul>
                  <Button disabled style={{backgroundColor:'#CF363B', color:'white', width:"90%"}}><a href="https://www.pinterest.com">Pinterest</a></Button></ul>
            </div>
            </Col>
            <Col span={6} style={{width:'auto', paddingTop:'10px', paddingBottom:'10px'}}>
            <div style={{width:400, paddingTop:'10px'}}>
              <h3>Subscribe Here</h3>
              <div>We will be adding new tours regularly. If you want to be notified when a new tour is added, please subscribe here.</div>
              <div style={{paddingTop:'10px'}}><Button style={{backgroundColor:'#3EBD93', color:'white', border:'none', width:'90%'}}>Sign Up For Free</Button></div>
            </div>
            </Col>
{/*            <Col span={6} style={{width:'auto', paddingTop:'10px'}}>
              <Card style={{width:400, paddingTop:'5px'}} cover={<img alt="example" src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png" />}>
                <Meta
                title="Wine Land"
                description="Vinyards, Barrels and Vin...."
                />
              </Card>
            </Col>
*/}
          </Row>
          <Footer style={{backgroundColor:'#7069FA', color:'white'}}><Row><Col span={12}>© Copyright 2019 - TinyTravel</Col><Col span={3} offset={9}>Contact - tt@tinytravel.com</Col></Row></Footer>
          {/*<Row><h1>Vinyards, Barrels and Vin....</h1></Row>*/}
        </div>
      )
    }
    if (this.state.home == 1 ){
      return (
        <div>
          <Author_Camus/>
        </div>
      );
    }
    if (this.state.home == 2 ){
      return (
        <div>
          <Author_Doyle/>
        </div>
      );
    }
    return (
      <div>
        <LondonFoodMarkets/>
      </div>
    );
  }
}

export default App;
