import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { Layout, Row, Col, Steps, Button, Icon, Card, Affix, Divider, PageHeader, Menu } from 'antd';
import 'antd/dist/antd.css';
import ReactMapGL from 'react-map-gl';
import {Marker, Popup, NavigationControl} from 'react-map-gl';
import CityPin from './city-pin';
import CityInfo from './city-info';
import Author_Doyle from './Author_Doyle';
import LondonFoodMarkets from './LondonFoodMarkets';
import CITIES from './cities.json';
import CAMUS from './camus.json';
import ReactGA from 'react-ga';

const { Header, Footer, Sider, Content } = Layout;
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZGRpdmlhY2NoaSIsImEiOiJjanJjODN4ZzUwaTFkNDNtbDNzdHZlbWJzIn0.pR8KP_hxx2LTl3wyoskB3w';
const mapstyle ='mapbox://styles/ddiviacchi/cjtpwtwpm0pzl1fnmml0zk0qo';

const { Meta } = Card;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const navStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '10px'
};
var array= [];
var currentcity;

class Author_Camus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewport: {
        width: "100%",  //old 70%
        height: 600,
        latitude: 48.863265,
        longitude: 2.3427148,
        zoom: 12,
        bearing:0,
        pitch:0,
      },
      popupInfo: null,
      home: 1,
    }
  }

  _updateViewport = (viewport) => {
     this.setState({viewport});
   }

  _renderCityMarker = (city, index) => {
     return (
       <Marker
         key={`marker-${index}`}
         longitude={city.longitude}
         latitude={city.latitude} >
         <CityPin size={20} onClick={() => this.setState({popupInfo: city})} />
       </Marker>
     );
  }

  //THIS FUNCTION RENDERS A PICTURE AND TITLE WHEN CLICKING ON THE MARKERS
  _renderPopup() {
  const {popupInfo} = this.state;

  return popupInfo && (
      <Popup tipSize={5}
        anchor="top"
        longitude={popupInfo.longitude}
        latitude={popupInfo.latitude}
        closeOnClick={false}
        onClose={() => this.setState({popupInfo: null})} >
        <CityInfo info={popupInfo} />
      </Popup>
    );
  }

  cityContent (){
    array = CAMUS.map((input, index) => (
      <div
      style={{ marginRight: 4, width: "20%", height:2000, opacity: .7, backgroundColor:'#7069FA', color: 'red', fontWeight:'bold' }}
        key={index}
        marker={input}
      >
      <div style={{padding:6, backgroundColor:'#1D0EBE'}}></div>
        <h1 style={{color:'white', padding:'5px'}}>{input.title}</h1>
        <h2 style={{color:'white', padding:'5px'}}>{input.number+1}) {input.location}</h2>
        <p style={{color:'white', padding:'5px'}}>{input.content}</p>
      </div>
    ))
    currentcity =  array.filter(j => j.key == this.state.popupInfo.number);
    return currentcity
  }

  contentStart (){
    return (
      <div
      style={{ marginRight: 4, width: "20%", height:2000, opacity: .7, backgroundColor:'#7069FA', color: 'red'}}
      >
      <div style={{padding:6, backgroundColor:'#1D0EBE'}}></div>
        <h1 style={{color:'white', padding:'5px', fontWeight:'bold' }}>Camus' Paris</h1>
        <h2 style={{color:'white', padding:'5px', fontWeight:500 }}>24 Hours in Paris</h2>
        <p style={{color:'white', padding:'5px', fontWeight:500}}>Albert Camus was one of the most influential writers of his time.  Understand what influenced the writer Albert Camus with his time in Paris. Click on the markers to plan and follow your tour.</p>
      </div>
    )
  }

  initializeReactGA() {
    ReactGA.initialize('UA-138208767-1');
    ReactGA.pageview('/camus');
  }

  render() {
    if (this.state.home == 1 ){
      return (
        <Layout style={{minHeight: 900, backgroundColor:'#F0F4F8'}}>
          <Header style={{backgroundColor:'#3525E6'}}>
            <Col span={8}>
              <h1 style={{color:'white', fontStyle:'italic'}}>TinyTravel</h1>
            </Col>
            <Col span={12} style={{paddingTop:'10px'}}>
              <Menu
              onClick={this.handleClick}
              selectedKeys={[this.state.current]}
              mode="horizontal"
              style={{background:'none', color:'white', border:'none'}}
              >
                <SubMenu title={<span style={{color:'white', fontWeight:'700'}} className="submenu-title-wrapper"><Icon type="compass"  />TinyTravel Tours</span>}>
                  <MenuItemGroup title="Authors & Artists" style={{backgroundColor:'#7DABD4', color:'white'}}>
                    <Menu.Item style={{margin:'0%'}} key="compass:1" onClick={() => this.setState({home : 1})}>Albert Camus</Menu.Item>
                    <Menu.Item style={{margin:'0%'}} key="compass:2" onClick={() => this.setState({home : 2})}>Sir James Conan Doyle</Menu.Item>
                  </MenuItemGroup>
                  <MenuItemGroup title="Localize" style={{backgroundColor:'#7DABD4', color:'white'}}>
                    <Menu.Item style={{margin:'0%'}} key="compass:3" onClick={() => this.setState({home : 3})}>London Food Markets</Menu.Item>
                  </MenuItemGroup>
                  <MenuItemGroup title="Vinyards, Barrels and Vin..." style={{backgroundColor:'#7DABD4', color:'white'}}>
                    <Menu.Item style={{margin:'0%'}} key="compass:3"> </Menu.Item>
                  </MenuItemGroup>
                </SubMenu>
                <Menu.Item disabled key="dollar" style={{color:'white'}}>
                  <Icon type="dollar" style={{color:'white'}}/><b style={{color:'white', fontWeight:'700'}}>Kindness Tip (on home page)</b>
                </Menu.Item>
              </Menu>
            </Col>
          </Header>
          <Content style={{padding: '5px', paddingRight:5, paddingLeft: 5, height: '100%'}}>
            <PageHeader
              onBack={() => window.history.back()}
              title="Albert Camus in Paris"
              style={{color:'#667C8A'}}
            >
            </PageHeader>
            <ReactMapGL
              {...this.state.viewport}
              onViewportChange={(viewport) => this.setState({viewport})}
              mapboxApiAccessToken={MAPBOX_TOKEN}
              mapStyle={mapstyle}
            >
              { CAMUS.map(this._renderCityMarker) }
              {//also changed city-info.js to take in locations
              }
              {/* Commenting renderPopup below so that no images show when clicking on the Markers for time being */}
              {/* this._renderPopup() */}
                  {//<div style={{width: "20%", height:2000, opacity: .7, backgroundColor:'#191A1A', color: 'white', fontWeight:'bold'}}>120px to affix top</div>
                  }
              <div>{this.state.popupInfo == null && this.contentStart()}</div>
              <div>{this.state.popupInfo != null && this.cityContent()}</div>

              <div className="nav" style={navStyle}>
                <NavigationControl onViewportChange={this._updateViewport} />
              </div>

            </ReactMapGL>
            <div>*Information created and adapted by Alice Kaplan from the article https://www.theparisreview.org/blog/2016/09/19/paris-camuss-notebooks/</div>
            <Divider style={{backgroundColor:'#3525E6'}}/>
            <Row type="flex" justify="space-around" style={{backgroundColor:'#F0F4F8', paddingTop:'40px', paddingBottom:'40px'}}>
              <Col span={6} style={{width:'auto', paddingTop:'10px'}}>
                <div style={{width:400, paddingTop:'10px', paddingBottom:'10px'}}>
                  <h3 style={{paddingLeft:'38px'}}>Companion Websites</h3>
                    <ul>
                      <i><a style={{width:"100%"}} href="www.soupduck.com">Soupduck</a></i> - Learn to eat authentic foods while traveling.
                    </ul>
                    <ul>
                      <i><a style={{width:"100%"}} href="www.withlocals.com">Withlocals</a></i> - Our tours are free and self completed, but at Withlocals you can hire a local to take you around.
                    </ul>
                    <ul>
                      <i><a style={{width:"100%"}} href="www.google.com">Google</a></i> - Be spontanteous, do your own research.
                    </ul>
                </div>
              </Col>
              <Col span={6} style={{width:'auto', paddingTop:'10px', paddingBottom:'10px'}}>
              <div style={{width:400, paddingTop:'10px'}}>
                <h3 style={{paddingLeft:'38px'}}>Our Social Sites</h3>
                  <ul>
                    <Button style={{backgroundColor:'#1DA1F2', color:'white', width:"90%"}}><a href="https://twitter.com/TinyTravel1">Twitter</a></Button>
                  </ul>
                  <ul>
                    <Button disabled style={{backgroundColor:'#517FA4', color:'white', width:"90%"}}><a href="https://www.google.com">Instagram</a></Button>
                  </ul>
                  <ul>
                    <Button disabled style={{backgroundColor:'#CF363B', color:'white', width:"90%"}}><a href="https://www.pinterest.com">Pinterest</a></Button></ul>
              </div>
              </Col>
              <Col span={6} style={{width:'auto', paddingTop:'10px', paddingBottom:'10px'}}>
              <div style={{width:400, paddingTop:'10px'}}>
                <h3>Subscribe Here</h3>
                <div>We will be adding new tours regularly. If you want to be notified when a new tour is added, please subscribe here.</div>
                <div style={{paddingTop:'10px'}}><Button style={{backgroundColor:'#3EBD93', color:'white', border:'none', width:'90%'}}>Sign Up For Free</Button></div>
              </div>
              </Col>
            </Row>
          </Content>
          <Footer style={{backgroundColor:'#7069FA', color:'white'}}><Row><Col span={12}>© Copyright 2019 - TinyTravel</Col><Col span={3} offset={9}>Contact - tt@tinytravel.com</Col></Row></Footer>
        </Layout>
      );
    }
    if (this.state.home == 2 ){
      return (
        <div>
          <Author_Doyle/>
        </div>
      );
    }
    return (
      <div>
        <LondonFoodMarkets/>
      </div>
    );
  }
}

export default Author_Camus;
